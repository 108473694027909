import nanoid from 'nanoid'
import { createContext, useContext, useEffect, useState } from 'react'
import { useRequest } from 'hooks/useRequest'
import { difference } from 'lodash'
import { useRouteMatch } from 'react-router'
import {
  fetchEligiblePackets,
  fetchDelivery,
  fetchPacket,
  updateDeliveryRequest,
  deliverDeliveryRequest,
  deliveryEvent,
  getEligibleFormIds,
  updateScanLookupCodeRequest,
} from './utils'

export const DeliveryStateContext = createContext()
export const DeliveryActionContext = createContext()

const DeliveryContextProvider = ({ children }) => {
  const match = useRouteMatch()

  const [currentDelivery, setCurrentDelivery] = useState()
  const [pendingUpdates, setPendingUpdates] = useState([])

  const applyOptimisticDeliveryUpdate = attrs => {
    const id = nanoid()
    setPendingUpdates(current => [...current, { id, change: attrs }])
    return () =>
      setPendingUpdates(current => current.filter(change => change.id !== id))
  }

  const [packets, setPackets] = useState([])

  const [packetSortType, setPacketSortType] = useState('alpha')
  const [packetSortOrder, setPacketSortOrder] = useState('ASC')
  const [packetSearchTerm, setPacketSearchTerm] = useState('')

  const [currentPacket, setCurrentPacket] = useState({
    scan_name: '',
    shift: {},
    scans: [],
  })

  const {
    makeRequest: makeDeliveryRequest,
    isLoading: isDeliveryLoading,
    hasErrors: fetchDeliveryHasErrors,
  } = useRequest(fetchDelivery, {
    onSuccess: async ({ delivery }) => {
      setCurrentDelivery(delivery)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: deliverDelivery } = useRequest(deliverDeliveryRequest, {
    onSuccess: ({ delivery }) => {
      setCurrentDelivery(delivery)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateDeliveryStatus } = useRequest(deliveryEvent, {
    onSuccess: ({ delivery }) => {
      setCurrentDelivery(delivery)
    },
  })

  const {
    makeRequest: updateScanLookupCode,
    errors: updateScanLookupCodeErrors,
  } = useRequest(updateScanLookupCodeRequest, {
    onSuccess: ({
      voter_registration_scan: {
        id,
        packet: { id: packet_id },
        lookup_code,
      },
    }) => {
      setPackets(packets => {
        const result = packets.map(packet =>
          packet.id === +packet_id
            ? {
                ...packet,
                scans: packet.scans.map(scan =>
                  scan.id === +id ? { ...scan, lookup_code } : scan
                ),
              }
            : packet
        )
        return result
      })
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makePacketRequest } = useRequest(fetchPacket, {
    onSuccess: ({ voter_registration_scan_batch }) => {
      setCurrentPacket(voter_registration_scan_batch)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: makePacketsRequest,
    isLoading: packetsLoading,
    hasErrors: fetchPacketsHasErrors,
  } = useRequest(fetchEligiblePackets, {
    onSuccess: ({ packets }) => {
      setPackets(
        packets.map(({ original_filename, ...packet }) => ({
          scan_name: original_filename,
          ...packet,
        }))
      )
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateDelivery, isLoading: isUpdateDeliveryLoading } =
    useRequest(updateDeliveryRequest, {
      onSuccess: ({ delivery }) => {
        setCurrentDelivery(delivery)
      },
    })

  useEffect(() => {
    if (match.params.id) {
      makeDeliveryRequest(match.params.id)
    }
  }, [match.params.id])

  const eligibleFormIds = getEligibleFormIds({
    delivery: currentDelivery,
    pendingUpdates,
    packets,
    searchTerm: packetSearchTerm,
  })

  const filteredPackets = packets.filter(({ scans }) =>
    scans.some(scan => eligibleFormIds.includes(scan.id))
  )

  const selectedFormIds = currentDelivery?.excluded_forms
    ? difference(
        eligibleFormIds,
        currentDelivery.excluded_forms.map(f => f.id)
      )
    : []

  const deliveryError = fetchDeliveryHasErrors || fetchPacketsHasErrors

  return (
    <DeliveryStateContext.Provider
      value={{
        selectedFormIds,
        eligibleFormIds,
        currentDelivery,
        isDeliveryLoading,
        isUpdateDeliveryLoading,
        deliveryError,
        packets,
        filteredPackets,
        packetsLoading,
        packetSortType,
        packetSortOrder,
        packetSearchTerm,
        currentPacket,
        pendingUpdates,
        updateScanLookupCodeErrors,
      }}
    >
      <DeliveryActionContext.Provider
        value={{
          setPacketSortType,
          setPacketSortOrder,
          setPacketSearchTerm,
          makePacketRequest,
          makePacketsRequest,
          deliverDelivery,
          setCurrentDelivery,
          updateDeliveryStatus,
          updateDelivery,
          setCurrentPacket,
          applyOptimisticDeliveryUpdate,
          updateScanLookupCode,
        }}
      >
        {children}
      </DeliveryActionContext.Provider>
    </DeliveryStateContext.Provider>
  )
}

export const useDeliveryState = () => useContext(DeliveryStateContext)
export const useDeliveryActions = () => useContext(DeliveryActionContext)

export default DeliveryContextProvider
