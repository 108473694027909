import { useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { TextBlock } from 'components'
import { Font } from '@politechdev/blocks-design-system'
import { routes } from 'layout/routesConfig'
import { useNavigation } from 'layout/NavigationProvider/NavigationProvider'
import { useNavigationPermission } from 'layout/NavigationPermissionProvider/NavigationPermissionProvider'
import styles from './Navigation.module.scss'

const cx = classNames.bind(styles)

const Navigation = () => {
  const navRef = useRef(null)
  const { isExpanded, setExpanded } = useNavigation()

  const closeNavigationIfOutside = event => {
    const { clientX: mouseX, clientY: mouseY } = event
    const {
      x: navX,
      y: navY,
      height,
      width,
    } = navRef.current.getBoundingClientRect()

    const insideX = navX < mouseX && mouseX < navX + width
    const insideY = navY < mouseY && mouseY < navY + height

    if (!insideX || !insideY) setExpanded(false)
  }

  useEffect(() => {
    if (isExpanded && navRef.current) {
      window.addEventListener('click', closeNavigationIfOutside)
      return () => window.removeEventListener('click', closeNavigationIfOutside)
    }
  }, [isExpanded])

  return (
    <nav
      ref={navRef}
      className={cx('navigation', {
        'navigation--expanded': isExpanded,
      })}
    >
      {Object.entries(routes).map(([block, routes]) => (
        <NavigationBlock key={block} label={block} routes={routes} />
      ))}
    </nav>
  )
}

const NavigationBlock = ({ label, routes }) => {
  const { hasPermission } = useNavigationPermission({
    blocks: routes?._meta?.blocks,
    permissions: routes?._meta?.permissions,
  })
  const { hasSomePermissions } = useNavigationPermission({
    permissions: Object.values(routes)
      .map(route => route?.permissions)
      .filter(permission => !!permission),
  })

  const { t } = useTranslation()

  if (!hasPermission || !hasSomePermissions) return null

  return (
    <div key={label} className={styles.navigation__block}>
      <TextBlock className={styles.navigation__block__label}>
        <Font.Label variant="section">{t(label)}</Font.Label>
      </TextBlock>
      {Object.entries(routes).map(([label, params]) => {
        if (label === '_meta') return null

        return (
          <NavigationItem
            key={label}
            className={cx('navigation__item')}
            label={label}
            activeClassName={cx('navigation__item--active')}
            {...params}
          />
        )
      })}
    </div>
  )
}

const NavigationItem = ({
  label,
  path,
  blocks,
  permissions,
  className,
  activeClassName,
}) => {
  const { t } = useTranslation()
  const { hasPermission, hasSomePermission } = useNavigationPermission({
    blocks,
    permissions,
  })
  const { setExpanded } = useNavigation()

  if (
    [
      'registration reports',
      'phone banking reports',
      'quality control reports',
      'field management',
      'petition_reports',
      'catalist reports',
    ].includes(label)
  ) {
    if (!hasSomePermission || !path) return null
  } else if (!hasPermission || !path) return null

  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={path}
      exact
      onClick={() => setExpanded(false)}
    >
      <Font.Action>{t(label)}</Font.Action>
    </NavLink>
  )
}

export default Navigation
