/* eslint-disable arrow-body-style */
import { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Text,
  Rectangle,
} from 'recharts'
import { useCurrent } from 'contexts/index'
import { useBackgroundRequest } from 'hooks'
import { requestChart, requestChartId } from 'requests/charts'
import { convertToUTCYMD } from 'utils/dateTime'
import { ProgressBar } from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import {
  formatTextforLabels,
  getMetricGroupAndLabel,
  getPlotBars,
  prepareChartData,
} from './plotUtils'
import styles from './CatalistPlot.module.scss'

const CatalistPlot = ({ chartType, dateRange, turfId }) => {
  const [chartData, setChartData] = useState([])
  const { currentTurfPerformsExternalQC } = useCurrent()

  const {
    makeRequest: chartRequest,
    isLoading: chartLoading,
    errorMsg: chartError,
    isSuccessful: chartSuccessful,
    response: chartResponse,
  } = useBackgroundRequest({
    key: chartType,
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters: {
          startDate: convertToUTCYMD(dateRange.start),
          endDate: convertToUTCYMD(dateRange.end),
        },
      }),
    requestData: requestChart,
  })

  useEffect(() => {
    chartRequest()
  }, [dateRange, turfId, chartType])

  useEffect(() => {
    if (chartResponse) {
      setChartData(chartResponse.rows)
    }
  }, [chartResponse])

  const [metricVar, groupVar, labelVar] = chartType
    ? getMetricGroupAndLabel(chartType)
    : []

  const wideData =
    chartData.length > 0
      ? prepareChartData(chartData, metricVar, groupVar, labelVar)
      : []

  const plotBars = getPlotBars(metricVar)

  const PercentTick = ({ x, y, payload }) => {
    const formattedLabel =
      labelVar === 'poc' ? 'POC' : formatTextforLabels(labelVar)

    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          textAnchor="end"
          verticalAnchor="middle"
          width={100}
          className={styles['tick-label']}
        >
          {`(${payload.value}% ${formattedLabel})`}
        </Text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload.map(obj => {
            const group_count = Math.round(obj.value * 0.01 * obj.payload.total)
            return (
              <span
                style={{ color: obj.color }}
              >{`${obj.name}: ${obj.value}% (${group_count} Applicants)`}</span>
            )
          })}
        </div>
      )
    }
    return null
  }

  const legendItems = bars =>
    bars.map(obj => (
      <li className={styles.legend__item}>
        <svg width="14" height="10" className={styles.legend__icon}>
          <Rectangle fill={obj.fill} width={12} height={10} />
        </svg>
        {obj.name}
      </li>
    ))

  const CustomLegend = () => {
    if (plotBars && metricVar === 'race') {
      const index = plotBars.findIndex(obj => obj.dataKey === 'caucasian')

      const pocBars = plotBars.slice(0, index)
      const otherBars = plotBars.slice(index)

      return (
        <ul className={styles.legend}>
          People of Color
          {legendItems(pocBars)}
          <br />
          Other
          {legendItems(otherBars)}
        </ul>
      )
    }

    if (plotBars && metricVar === 'voter_status') {
      const index = plotBars.findIndex(obj => obj.dataKey === 'unregistered')

      const matchedBars = plotBars.slice(0, index)
      const unmatchedBars = plotBars.slice(index)

      return (
        <ul className={styles.legend}>
          Matched
          {legendItems(matchedBars)}
          <br />
          Unmatched
          {legendItems(unmatchedBars)}
        </ul>
      )
    }
    return null
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ProgressBar show={chartLoading} />
      <CardError hide={!chartError} message={chartError} />
      {chartSuccessful && (
        <BarChart
          data={wideData}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 55,
          }}
          layout="vertical"
        >
          <XAxis
            type="number"
            domain={[0, 100.1]}
            tickFormatter={value => `${Math.round(value)}%`}
          />
          <YAxis
            type="category"
            dataKey="label_percent"
            axisLine={false}
            tickLine={false}
            tick={<PercentTick />}
          />
          <YAxis
            type="category"
            dataKey="group"
            width={145}
            tickLine={false}
            axisLine={false}
            yAxisId="group"
            tickMargin={25}
          />

          {plotBars.map((obj, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey={obj.dataKey}
              stackId="a"
              fill={obj.fill}
              name={obj.name}
            />
          ))}
          <Legend
            content={CustomLegend}
            layout="vertical"
            verticalAlign="middle"
            align="right"
            wrapperStyle={{
              paddingLeft: '20px',
            }}
          />
          <Tooltip
            content={CustomTooltip}
            cursor={{ fill: 'none' }}
            shared={false}
            wrapperStyle={{
              backgroundColor: 'white',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          />
          {metricVar === 'race' ? (
            <ReferenceLine x={75} stroke="#5C7192" strokeDasharray="10" />
          ) : null}
        </BarChart>
      )}
    </ResponsiveContainer>
  )
}

export default CatalistPlot
